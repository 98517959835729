import React from 'react';
import '../App.css'
import fullDark from '../svg/fullDark.svg'
import crux from '../svg/crux.svg'
import absolutePath from '../svg/absolutePath.svg'
function App() {
  const services = [
    {
      logo: absolutePath, 
      alt: 'Absolute Path',
      description: 'Weight & Balance, Flight Planning, and Flight Following. '
    },
    {
      logo: crux,
      alt: 'CRUX',
      description: 'Highly detailed ACARS infrastructure.'
    },
  ];

  return (
    <div className="h-screen w-screen px-16 py-4 bg-navy-800 flex flex-col justify-center items-center">
      
      <div className='flex flex-col gap-1'>
        <img src={fullDark} alt="FerrLab logo" className='w-[50vh]' />
        <p className='text-white text-center text-xl'>Coming soon for your Virtual Airline:</p>
      </div>

      <div className={`max-w-4xl  grid grid-cols-1 ${services.length === 2 ? 'md:grid-cols-2' : ''} ${services.length > 2 ? 'md:grid-cols-3' : ''}  grid-flow-row gap-8 mt-8`}>

        {services.map((service, index) => (

        <div className='flex flex-col gap-4 rounded-md px-4 bg-navy-600 justify-center items-center hover:shadow-lg hover:scale-105 hover:shadow-navy-400 transition'>
          <div class="grow w-full flex justify-center items-center">
          <img src={service.logo} alt={service.alt} className='w-2/3  mt-8' />
          </div>
          <div class="flex flex-col items-center justify-center mt-4">
          <p className='text-white break-auto text-center pb-8'>{service.description}</p>
          </div>
        </div>

        ))}


      </div>
    </div>
  );
}

export default App;
